import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

import './assets/scss/icons.scss';

import {
  Route,
  Routes
} from "react-router-dom";


import Index from "./pages/index.js";

import Loader from "./component/Loader";
import About from "./pages/about.js";
import Services from "./pages/services.js";
import CaseStudy from "./pages/case-study.js";
import Contact from "./pages/contact.js";
import Products from "./pages/products.js";



// import Services from "./component/Services.js";

export default function App() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/case-studies" element={<CaseStudy />} />
        <Route path="/contact" element={<Contact />} />

      </Routes>
    </>
  );

}
