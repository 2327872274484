import React from "react";
// import Services from "../component/Services";
// import About from "../component/About";
// import Pricing from "../component/Pricing";
// import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
// import Portfolio from "../component/Portfolio";
// import Review from "../component/Testimonial";
// import Switcher from "../component/Switcher";
// import Process from "../component/Process";
// import Hero from "../component/Hero";
import Header from "../component/Header";
// import Products from "../component/Products";

/**
 * Index Component
 */
export default function Index() {
  return (
    <>
      <div>
        <Header />
        <Contact />
        <Footer />
        {/* <Switcher /> */}
      </div>

    </>
  );

}

