import React from "react";
// import Services from "../component/Services";
// import About from "../component/About";
// import Pricing from "../component/Pricing";
// import Blog from "../component/Blog";
// import Contact from "../component/Contact";
import Footer from "../component/Footer";
// import Portfolio from "../component/Portfolio";
// import Review from "../component/Testimonial";
// import Switcher from "../component/Switcher";
// import Process from "../component/Process";
import Header from "../component/Header";
// import Logos from "../component/logos";
// import Portfolio from "../component/Portfolio";
import Portfolio1 from "../component/Portfolio1";
// import Products from "../component/Products";
// import Logos from "../component/l";


/**
 * Index Component
 */
export default function Index() {
  return (
    <>
      <div>
        <Header />
        {/* <About />
        <Process />
        <Services />
        <Portfolio />
        <Review />
        <Products /> */}
        {/* <Logos/> */}
          <Portfolio1 />
        {/* <Contact />  */}
        <Footer />
        {/* <Switcher /> */}
      </div>

    </>
  );

}

