import React from "react";
// import { Link } from "react-router-dom";
// import { Link as Link2 } from 'react-router-dom';

// import Image1 from "../assets/images/about.jpg";
// import Image2 from "../assets/images/blog/2.jpg";
import Image1 from "../assets/images/box.jpg";
import Image2 from "../assets/images/crate.jpg";
import Image3 from "../assets/images/heavy.png";
import Image4 from "../assets/images/heavy2.png";
import Image5 from "../assets/images/skid.png";
import Image6 from "../assets/images/steel.png";
import Image7 from "../assets/images/last.png";
import Image8 from "../assets/images/cover.png";
export default function Services1() {
    const team = [
        {
          id:1,
          image: Image1,
          // title: "Who We Are ?",
          subtitle: "The Green Space Plywood Boxes and Pallets",
          subtext:
            "Plywood boxes and pallets from The Green Space are perfect for transporting heavy, sensitive, and high-value goods. As a leading provider of wooden box packaging, we offer a range of exclusive nail-less, collapsible export plywood boxes, available in sizes from small three-piece solutions to large six-piece products.Our plywood boxes and pallets can be fully customised, from dimensions to pallets, printing, handles, and locking mechanisms. We use only the highest quality plywood, sourced from certified vendors and rigorously tested. The type, grade, and quality of plywood used are tailored to meet the specific packaging design and customer requirements",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Tailored to transport a wide variety of products",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Designed to withstand the most demanding conditions.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Nail-less construction ensures convenience.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Tested, approved, and accepted globally.",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "Client details can be easily printed on the plywood.",
            },
          ],
        },
        {
          id:2,
          image: Image2,
          // title: "Who We Are ?",
          subtitle: "The Green Space Custom Wood Crates",
          subtext:
            "At The Green Space, we manufacture both small, highly technical wood crates and large containers. Our wood crates are designed for simple and efficient unpacking and are ideal for industrial applications. We are proficient in producing special-purpose crates assembled using nails and wood screws, as well as collapsible crates using metal brackets.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Ensures the safe transport of goods",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Durable and cost-effective packaging solution.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Tailor to your specific needs.",
            },
            // {
            //   id: 4,
            //   icon: "/checkmark.svg",
            //   title: "Pallet Size & Design: Customizable",
            // },
            // {
            //   id: 5,
            //   icon: "/checkmark.svg",
            //   title: "Pallet Size & Design: Customizable",
            // },
          ],
        },

        {
          id:3,
          image: Image3,
          // title: "Who We Are ?",
          subtitle: "Heavy-Duty Corrugated (HDC) Packaging Solutions",
          subtext:
            " At The Green Space , we offer industry-leading heavy-duty corrugated boxes and boards. Our corrugated boards are crafted from imported water-resistant virgin kraft liner board made of long fibres, ensuring superior strength and durability. The high-strength fluting paper, coupled with larger flute profiles (AC, AA, ACA, AAA, ABC) and moisture-proof glue, results in corrugated boards with excellent compression strength, puncture resistance, and weatherability.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "High Strength-to-Weight Ratio: Lightweight yet extremely strong",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Easy Handling: Simplifies packing and unpacking processes.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "High Stacking Strength: Ideal for stacking and storage.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Environmentally Friendly: Made from 100% recyclable materials.",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "Printable: Customisable for branding and information.",
              
            },
            {
              id: 6,
              icon: "/checkmark.svg",
              title: "Weather-Resistant: Protects contents from environmental factors.",
              
            },
            {
              id: 7,
              icon: "/checkmark.svg",
              title: "No Fumigation Required: Ready for use without additional treatments.",
              
            },
          ],
        },

        {
          id:4,
          image: Image4,
          // title: "Who We Are ?",
          subtitle: "Heavy Machinery Packaging Solutions",
          subtext:
            "Shipping heavy machinery requires robust solutions to ensure equipment arrives damage-free. The Green Space’s Heavy Machinery Packaging Solutions offer superior protection against shock, vibration, and corrosion. With decades of experience serving the oil and gas, power, defense, locomotive, and automobile industries, we understand the unique challenges of packaging heavy and bulky equipment.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Ensures maximum protection during transit.",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Can be managed with various types of equipment.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Capable of accommodating oversized machinery.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Provides a low-cost packaging solution.",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "We ensure proper handling of the machine's center of gravity for optimal load balance",
            },
          ],
        },

        {
          id:5,
          image: Image5,
          // title: "Who We Are ?",
          subtitle: "Heavy Duty Wood Skids and Boxes",
          subtext:
            "When it comes to packaging immense and extraordinarily expensive products, The Green Space can design and fabricate solutions for items weighing tens or even hundreds of tons. Leveraging our extensive knowledge, material expertise, and design proficiency, we deliver cost-effective heavy-duty wooden and corrugated packaging solutions for your most highly-valued products.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Provides exceptional protection for heavy and valuable items.",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Compatible with various types of equipment for easy handling.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Capable of accommodating oversized and heavy machinery.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Offers a low-cost solution without compromising on quality",
            },
            // {
            //   id: 5,
            //   icon: "/checkmark.svg",
            //   title: "Pallet Size & Design: Customizable",
            // },
          ],
        },

        {
          id:6,
          image: Image6,
          // title: "Who We Are ?",
          subtitle: "Expendable Steel Skids",
          subtext:
            "The Green Space manufactures expendable steel skids, essential for shipping automobiles and power equipment in CKD (Completely Knocked Down) and SKD (Semi Knocked Down) forms. Utilising our specialised ERP system, we ensure all SKUs are packed and shipped efficiently.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Provides robust support while keeping weight minimal.",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Maximizes parts shipment, reducing shipping costs.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Simplifies the shipping process without additional treatments.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Tailored to meet specific shipping requirements.",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "Facilitates precise design and strength simulation.",
            },
          ],
        },

        {
          id:7,
          image: Image7,
          // title: "Who We Are ?",
          subtitle: "Paper Pallets",
          subtext:
            " The Green Space offers eco-friendly paper pallets that are lightweight, recyclable, and customizable. Coated with a waterproof layer, these pallets reduce freight costs, enhance safety, and maintain hygiene. They’re easy to handle, biodegradable, and ideal for protecting sensitive equipment while supporting environmental sustainability.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Provides robust protection against damage.",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Ensures long-lasting use.",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Prevents moisture damage.",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Shields against harmful UV rays.",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "Performs reliably in temperatures as low as 40° below zero.",
                                                          
            },
          ],
        },

        {
          id:8,
          image: Image8,
          // title: "Who We Are ?",
          subtitle: "Equipment Covers",
          subtext:
            "At The Green Space, we offer a range of Equipment Covers designed to protect sensitive materials and equipment. Crafted from low-density polyethylene (LDPE), these covers come in various sizes and thicknesses, making them ideal for safeguarding computers, televisions, wheelchairs, commodes, oxygen tanks, and other healthcare items. We also provide specialised covers for Military Equipment, Aerospace Components, Shop Equipment, and Engines.",
          btn: "Get In Touch",
          features: [
            {
              id: 1,
              icon: "/checkmark.svg",
              title: "Tear, Puncture, and Abrasion Resistance",
            },
            {
              id: 2,
              icon: "/checkmark.svg",
              title: "Rugged Durability",
            },
            {
              id: 3,
              icon: "/checkmark.svg",
              title: "Cold Crack Resistance to 40° Below Zero",
            },
            {
              id: 4,
              icon: "/checkmark.svg",
              title: "Excellent UV Resistance",
            },
            {
              id: 5,
              icon: "/checkmark.svg",
              title: "Weather-Proof Tarp",
            },
            {
              id: 6,
              icon: "/checkmark.svg",
              title: "Moisture Resistance",
            },
            {
              id: 7,
              icon: "/checkmark.svg",
              title: "Extended Service Life",
            },
          ],
        },

      ];

      return (
        <>
          {/* Start */}
          <section className="relative bg-gray-50 dark:bg-slate-800" id="about">
            <div className="container mx-auto mt-28">
              <div className="grid grid-cols-1 pb-8 text-center">
                <h6 className="text-[#69A41D] text-5xl font-medium uppercase mb-2">
                  Our Products
                </h6>
                <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto text-xl">
                  We offer a range of high-quality products designed to meet your
                  packaging needs with precision and reliability.
                </p>
              </div>
      
              {team.map((item, key) => (
                <div
                  key={key}
                  className={`lg:flex md:flex ${item.id % 2 === 0 && "lg:flex-row-reverse"} lg:items-center md:items-center mb-12`}
                >
                  {/* Image Section */}
                  <div className="lg:w-5/12 md:w-5/12 w-full">
                    <div className="relative flex justify-center">
                      <img
                        src={item.image}
                        className="rounded-lg shadow-lg h-auto w-full lg:max-w-xs md:max-w-xs mt-12"
                        alt={item.title}
                      />
                    </div>
                  </div>
      
                  {/* Text Section */}
                  <div className="lg:w-7/12 md:w-7/12 w-full mt-6 lg:mt-0 md:mt-0">
                    <div className="lg:ms-7 md:ms-7 px-4">
                      <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                        {item.subtitle}
                      </h3>
                      <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mb-4">
                        {item.subtext}
                      </p>
      
                      <ul className="text-xl text-slate-400 dark:text-slate-300">
                        {item.features?.map((i, idx) => (
                          <li key={idx} className="flex items-center text-[16px] mb-2">
                            <img src={i.icon} className="h-5 w-5 mr-4" alt="icon" />
                            {i.title}
                          </li>
                        ))}
                      </ul>
      
                    <div className="relative mt-10">
                    <a
                      href="https://wa.me/918830552784"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn bg-[#18443A] border-[#18443A] hover:border-[#18443A] text-white rounded-md"
                    >
                      {item.btn}
                    </a>
                  </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </>
      );
    }
  
