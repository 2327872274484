import React from "react";
// import { Link } from "react-router-dom";
import { Link as Link2 } from 'react-router-dom';

import Image1 from "../assets/images/box.jpg";
import Image2 from "../assets/images/crate.jpg";



// import StrategyImage from "../assets/images/svg/design-thinking.svg";
// import departmentImage from "../assets/images/svg/coding.svg";
// import launchImage from "../assets/images/svg/office-desk.svg";

export default function Services() {
  const team = [
    {
      title: 'On Site Packaging',
      icon: 'adjust-circle',
      subtext: 'Our on-site packaging solutions provide customised packaging services that adapt to your specific requirements, ensuring that products are securely packed and ready for distribution without disrupting your workflow.',
      image: Image1,
      link:"/services",
    },
    {
      title: 'Plywood Boxes and Pallets',
      icon: 'circuit',
      subtext: 'Our comprehensive packaging solutions include durable plywood boxes, and reliable pallets. Our high-quality plywood boxes offer exceptional protection and customization for various needs, while our robust pallets ensure safe and organised transport of goods.',
      image: Image2,
      link:"/products"
    },
    // {
    //   title: 'Handled By Expert',
    //   icon: 'fire',
    //   subtext: 'The phrasal sequence of the is now so that many campaign and benefit'
    // },
    // {
    //   title: 'Discussion For Idea',
    //   icon: 'shopping-basket',
    //   subtext: 'The phrasal sequence of the is now so that many campaign and benefit'
    // },
    // {
    //   title: 'Increase Conversion',
    //   icon: 'flower',
    //   subtext: 'The phrasal sequence of the is now so that many campaign and benefit'
    // },
    // {
    //   title: 'Sales Growth Idea',
    //   icon: 'flip-h',
    //   subtext: 'The phrasal sequence of the is now so that many campaign and benefit'
    // }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">What We Do ?</h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">Perfect Solution For Your <br /> Business</h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">Comprehensive Packing and Logistics Solutions for Efficient, Secure, and Timely Deliveries</p>
            </div>
          </div> */}

          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">What We Do ?</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium text-[#18443A]">Perfect Solution For Your Business</h3>

            <p className="text-slate-400  max-w-xl mx-auto">Efficient and Secure Packing Solutions for Your Business Success</p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-6 mt-8">
            {team.map((item, key) => (
              <div key={key} className="blog relative rounded-md shadow text-[#5A7A6D] dark:shadow-slate-800 overflow-hidden">
                {/* <img src={item.image} alt="" /> */}
                <div className="content p-6">
                  <Link2 to="#" className="text-2xl hover:text-[#69A41D] dark:text-white dark:hover:text-[#69A41D] transition-all duration-500 ease-in-out font-medium">{item.title}</Link2>
                  <p className="text-slate-400 mt-3 text-lg">{item.subtext}</p>

                  <div className="mt-5">
                    <Link2 to={item.link} className="btn btn-link hover:text-[#69A41D] dark:hover:text-[#69A41D] after:bg-[#69A41D] dark:text-white transition duration-500 text-lg">Read More <i className="uil uil-arrow-right"></i></Link2>
                  </div>  
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">Work Process</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Digital System For Our Business</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Strategy</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Development</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={departmentImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={launchImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Launch</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );

}
