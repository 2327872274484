import React from 'react'
import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
// import launchImage from "../assets/images/svg/office-desk.svg";

const Process = () => {
  return (
    <div className="container md:mt-16 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">Work Process</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium text-[#18443A]">Empowering Businesses with Sustainable Packaging</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Delivering Excellence and Sustainability for Industries Worldwide.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Mission</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our mission is to provide high-quality, reliable, and sustainable wooden packaging solutions that meet industry standards and exceed customer expectations. We are committed to innovation, precision, and ethical business practices, delivering products that support the efficiency and safety of our clients' operations. By leveraging modern technology, expert craftsmanship, and a customer-centric approach, we aim to be the preferred partner in the wooden packaging industry, ensuring timely and dependable service for all our clients.
                      .</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Vision</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">To be a global leader in sustainable wooden packaging solutions, recognized for our commitment to quality, innovation, and environmental stewardship. We envision a future where our products set the benchmark for durability and efficiency, empowering industries worldwide to operate more sustainably. Through continuous improvement and a dedication to excellence, we aspire to expand our reach, foster long-term partnerships, and contribute positively to the communities and ecosystems we serve.
                      </p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={departmentImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={launchImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Launch</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">The generated injected humour, or non-characteristic words etc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
  )
}

export default Process