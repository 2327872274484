import React from 'react'
// import BackgroudImage from "../assets/images/bg/2.jpg";
import { Link as Link2 } from "react-router-dom";
import ReactPlayer from 'react-player'
 
const Hero = () => {
  return (
    <section
          className="w-full table relative bg-center bg-cover "
          id="home"
        >
            {/* <ReactPlayer
                className='react-player absolute object-cover'
                url='https://videos.pexels.com/video-files/2775508/2775508-uhd_2560_1440_30fps.mp4'
                width='100%'
                height='auto'
                style={{ objectFit: 'cover'}}
                playing={true}
                loop={true}
                muted={true}
                
            /> */}
            <div style={{objectFit: 'cover', width: '100%', height: 'auto'}} className="react-player absolute object-cover"><video src="https://videos.pexels.com/video-files/2775508/2775508-uhd_2560_1440_30fps.mp4" style={{width:'100%', height: "auto", objectFit: 'cover'}} preload="auto" muted='true' autoplay='true' loop=""></video></div>
            {/* <video src="https://videos.pexels.com/video-files/2775508/2775508-uhd_2560_1440_30fps.mp4" style={{width:'100%', height: "auto"}} preload="auto" autoplay="" loop=""></video> */}

          <div className="absolute inset-0 bg-black opacity-80 "></div>
          <div className="container relative py-36 lg:py-80 ">
            <div className="grid grid-cols-1 ">
            
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
              Global Logistic Packaging<br />
              Solutions{" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
                ></span>
              </h4>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
              Innovative solutions bringing total logistic efficiency and creating global value.
              </p>

              <div className="relative mt-10">
                <Link2
                  to="#"
                  className="btn bg-[#18443A] border-[#18443A] hover:border-[#18443A] text-white rounded-md"
                >
                  Get Started
                </Link2>
              </div>
            </div>
          </div>
        </section>
  )
}

export default Hero