import React from "react";
// import Services from "../component/Services";
// import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
// import Portfolio from "../component/Portfolio";
// import Review from "../component/Testimonial";
// import Switcher from "../component/Switcher";
// import Process from "../component/Process";
import Header from "../component/Header";
import Services1 from "../component/Services1";
import Cta1 from "../component/Cta1";
// import Products from "../component/Products";


/**
 * Index Component
 */
export default function Index() {
  return (
    <>
      <div>
        <Header />
        {/* <About /> */}
        {/* <Process /> */}
        {/* <Services /> */}
       <Services1 />
        {/* <Portfolio /> */}
        {/* <Review /> */}
        {/* <Products /> */}
        {/* <Contact /> */}
        <Cta1 />
        <Footer />
        {/* <Switcher /> */}
      </div>

    </>
  );

}

