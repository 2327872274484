export const mainMenu = [
    {
        title: 'Home',
        href: '/'
    },
    // {
    //     title: 'About',
    //     href: '/about'
    // },
    {
        title: 'Services',
        href: '/services'
    },
    {
        title: 'Products',
        href: '/products'
    },
    {
        title: 'Case Studies',
        href: '/case-studies'
    },
    {
        title: 'Contact Us',
        href: '/contact'
    }
]