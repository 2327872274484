import React from "react";
import "react-18-image-lightbox/style.css";
import { Link } from "react-router-dom";

import CTABackground from "../assets/images/bg/cta.png";

/**
 * Portfolio section
 */
export default function Cta1() {
  return (
    <>
      {/* Project End  */}
      <div className="my-24">
        <section
          style={{ backgroundImage: `url(${CTABackground})` }}
          className="py-24 w-full table relative bg-center bg-cover"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">
              Ready to Elevate your packaging strategy with our expert solutions today?
              </h3>

              <p className="text-white opacity-50 max-w-xl mx-auto">
              Transform your packaging strategy with our reliable and precisely engineered wooden pallets, boxes, and crates. The Green Space delivers superior quality and efficiency tailored to your requirements.
              </p>

              <div className="relative mt-10">
              <div className="relative mt-10">
              <Link 
                to="https://wa.me/918830552784" 
                className="btn bg-[#69A41D] text-[#18443A] border-[#69A41D] rounded-md"
                target="_blank" 
                rel="noopener noreferrer"
              >
                Get In Touch!
              </Link>
            </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
