import React from "react";
// import { Link as Link2 } from "react-router-dom";
import AboutImage from "../assets/images/homeintro.jpg";

// import ModalVideo from "react-modal-video";
// import "../../node_modules/react-modal-video/scss/modal-video.scss";


export default function About() {
  // const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  {/* <Link2 to="#" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-[#69A41D]">
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i> */}
                  {/* </Link2> */}
                </div>
              </div>
            </div>
            {/* end col */}
                  
            
            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">Introduction</h6>
                <h3 className="mb-4 text-[#18443A]  md:text-2xl text-xl font-medium">Our Company Story</h3>
                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">The Green Space is engaged in supplying an impeccable range of Wooden Pallets, Wooden Boxes & Wooden Crates, which are Heat Treated as per ISPM & are admired for their reliability, compact design, dimensional accuracy, longer functional life, and high efficiency.
                  We march ahead with a highly progressive approach aimed at result orientation which is based on business ethics and management techniques. We have created an excellent reputation on the platforms of quality products and customized packaging service for our esteemed clients in various industries. Well-resourced with abundant stock, ultra-modern machines, and quality testing tools, all our departments are managed by experienced professionals. In order to ensure the high quality of products, these are developed in compliance with industry standards. Furthermore, we regularly test these products on various parameters.
                  Due to the effective coordination of the logistic experts, we have been able to deliver the finished products at customers' end within the promised time frame.
                  .</p>

                <div className="relative mt-10">
                <a href="#portfolio" className="btn text-white rounded-md" style={{ backgroundColor: '#18443A', borderColor: '#18443A' }}>View Portfolio</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      /> */}
    </>
  );

}

