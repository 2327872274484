import React, { useState } from "react";
// import { Link } from "react-scroll";
import { Link } from "react-router-dom";

import LogoLight from "../assets/images/logo_dark_png.png";
import LogoDark from "../assets/images/logo_light_png.png";

// import Typed from 'typed.js';
import { mainMenu } from "../constants/menu";

const Header = ({ home }) => {
  const [isOpen, setMenu] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [scrolled, setScrolled] = useState(false);  
  window.addEventListener("scroll", windowScroll);
  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  // setTimeout(() => {
  //   new Typed("#typed", {
  //     strings: [
  //       "<b>Business</b>",
  //       "<b>Startups</b>",
  //       "<b>Digital Agency</b>",
  //       "<b>Marketing</b>",
  //     ],
  //     backDelay: 2000,
  //     loop: true,
  //     startDelay: 300,
  //     typeSpeed: 100,
  //     backSpeed: 100,
  //   });
  // }, 500);

  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
      console.log("scrolled");
      setScrolled(true);
    } else {
      navbar.classList.remove("is-sticky");
      console.log("notscrolled");
      setScrolled(false);
    }
  }
  return (
    <nav className="navbar" id="navbar">
      <div className="container flex flex-wrap items-center justify-end">
        <a className="navbar-brand" href={"/"}>
          <span className="inline-block dark:hidden">
            {home ? (
              <>
              <img 
                src={LogoDark} 
                className="l-dark" 
                alt="The Green Space" 
                style={{ height: '70px', width: '100px' }} 
              />
              <img 
                src={LogoLight} 
                className="l-light" 
                alt="The Green Space" 
                style={{ height: '70px', width: '100px' }} 
              />
            </>
            
            ) : (
              <img src={LogoDark} className="" alt="The Green Space"  style={{ height: '70px', width: '100px' }}  />
            )}
          </span>
          {/* <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt=""
              /> */}
        </a>

        <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
          <ul className="list-none menu-social mb-0">
            <li className="inline ms-1">
              <Link to="#">
                <span className="login-btn-primary">
                  {/* <span className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                    <i className="uil uil-github"></i>
                  </span> */}
                </span>
                {/* <span className="login-btn-light">
                  <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                    <i className="uil uil-github"></i>
                  </span>
                </span> */}
              </Link>
            </li>
            
            <li className="inline ms-1">
            <span className="login-btn-primary">
              <a href="https://wa.me/8830552784" target="_blank" rel="noopener noreferrer">
                <span className="btn btn-sm btn-icon rounded-full bg-white hover:bg-[#18443A] border-white text-black">
                  <i className="uil uil-phone"></i>
                </span>
              </a>
            </span>
            <span className="login-btn-light">
              <a href="https://wa.me/8830552784" target="_blank" rel="noopener noreferrer">
                <span className="btn btn-sm btn-icon rounded-full bg-white hover:bg-[#18443A] border-white text-black">
                  <i className="uil uil-phone"></i>
                </span>
              </a>
            </span>
          </li>

            
            
          <li className="inline ms-1">
          <a href="mailto:info@greenspace.in" className="ms-1"> 
            <span className="login-btn-primary">
              <span className="btn btn-sm btn-icon rounded-full bg-white hover:bg-[#18443A] border-white text-black">
                <i className="uil uil-envelope"></i>
              </span>
            </span>
            <span className="login-btn-light">
              <span className="btn btn-sm btn-icon rounded-full bg-white hover:bg-[#18443A] border-white text-black">
                <i className="uil uil-envelope"></i>
              </span>
            </span>
          </a>
        </li>


            {/* <li className="inline ms-1">
            <a href="https://www.instagram.com/_thegreenspace.in_/" target="_blank" rel="noopener noreferrer">
            <span className="login-btn-primary">
              <span className="btn btn-sm btn-icon rounded-full bg-white hover:bg-[#18443A] border-white text-black">
                <i className="uil uil-instagram"></i>
              </span>
            </span>
            <span className="login-btn-light">
              <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-[#18443A] dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                <i className="uil uil-instagram"></i>
              </span>
            </span>
          </a>
         </li> */}

          </ul>
          <button
            type="button"
            className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
            onClick={toggleMenu}
          >
            <span className="sr-only">Navigation Menu</span>
            <i className="mdi mdi-menu mdi-24px"></i>
          </button>
        </div>

        <div
          className={`${
            isOpen === true ? "hidden" : "block"
          } navigation lg_992:order-1 lg_992:flex`}
          id="menu-collapse"
        >
          <ul
            className={`navbar-nav ${home ? "nav-light" : "nav-dark"}`}
            id="navbar-navlist"
          >
            {mainMenu.map((i, index) => (
              <Link className="nav-item" to={i.href} key={index}>
                <span className="nav-link">{i.title}</span>
              </Link>
            ))}
            {/* <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                >
                  <span className="nav-link">About us</span>
                </Link>
                <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Services</span>
                </Link>
                <Link
                  className="nav-item"
                  to="portfolio"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Portfolio</span>
                </Link>
                <Link
                  className="nav-item"
                  to="testi"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Review</span>
                </Link> */}
            {/* <Link
                  className="nav-item"
                  to="pricing"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Pricing</span>
                </Link> */}
            {/* <Link
                  className="nav-item"
                  to="blog"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Blog</span>
                </Link>
                <Link
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Contact us</span>
                </Link> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
