import React from "react";
// eslint-disable-next-line no-unused-vars
import { Link as Link2 } from 'react-router-dom';

import Image1 from "../assets/images/box.jpg";
import Image2 from "../assets/images/crate.jpg";

export default function Services1() {
  const team = [
    {
      id: 1,
      image: Image1,
      subtitle: "Innovative Packaging Solutions",
      subtext:
        "At The Green Space, we provide innovative packaging solutions tailored to your unique requirements, informed by a thorough understanding of your entire supply chain dynamics. Our goal is to ensure your products reach customers undamaged and with minimal packaging.",
      btn: "Get In Touch",
      features: [
        { id: 1, icon: "/checkmark.svg", title: "Customised Packaging Solutions" },
        { id: 2, icon: "/checkmark.svg", title: "Packaging Solutions That Ensure Safe Product Delivery" },
        { id: 3, icon: "/checkmark.svg", title: "Minimization of Transportation and Warehousing Costs" },
        { id: 4, icon: "/checkmark.svg", title: "Packaging Automation" },
        { id: 5, icon: "/checkmark.svg", title: "Packaging Automation" },
      ],
    },
    {
      id: 2,
      image: Image2,
      subtitle: "On Site Packaging",
      subtext:
        "Our expert team specialises in comprehensive packaging solutions designed to protect your products at every stage. The primary packaging acts as the first line of defence, effectively shielding your products from dust, moisture, and other potential contaminants.",
      btn: "Get In Touch",
    },
  ];

  return (
    <>
      <section className="relative bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto mt-28">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-[#69A41D] font-medium text-5xl uppercase mb-2">
              Our Services
            </h6>
            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto text-xl">
              Comprehensive Services for Sustainable and Secure Packaging.
            </p>
          </div>
          {team.map((item, key) => (
            <div
              key={key}
              className={`lg:flex ${item.id % 2 === 0 && "flex-row-reverse"} lg:grid-cols-12 md:grid-cols-2 gap-10 items-center`}
            >
              <div className="lg:col-span-5 my-12 lg:my-0">
                <div className="relative">
                  <img
                    src={item.image}
                    className="rounded-lg shadow-lg relative h-96 w-96 mt-12"
                    alt=""
                  />
                </div>
              </div>
              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white mt-20">
                    {item.subtitle}
                  </h3>

                  <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mb-4">
                    {item.subtext}
                  </p>

                  <ul className="text-xl text-slate-400 dark:text-slate-300">
                    {item.features?.map((i) => (
                      <li key={i.id} className="flex flex-row items-center text-[16px]">
                        <img src={i.icon} className="size-4 mr-4" alt="Checkmark icon" />
                        {i.title}
                      </li>
                    ))}
                  </ul>

                  <div className="relative mt-10">
                    <a
                      href="https://wa.me/918830552784"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn bg-[#18443A] border-[#18443A] hover:border-[#18443A] text-white rounded-md"
                    >
                      {item.btn}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
