// import React, { useState } from "react";
// import { Link as Link2 } from "react-router-dom";
// import productsData from "@/content/products.json";

// import AboutImage from "../assets/images/about.jpg";
// import ModalVideo from "react-modal-video";
// import "../../node_modules/react-modal-video/scss/modal-video.scss";
// import Card from "./Card";
import Image1 from "../assets/images/about.jpg";
// import Image2 from "../assets/images/blog/2.jpg";

export default function Products() {
  // const [isOpen, setOpen] = useState(false)
  const team = [
    {
      id:1,
      image: Image1,
      // title: "Who We Are ?",
      subtitle: "Our Company Story",
      subtext:
        "Start working with Upwind that can provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with real content. This is required when, for example, the final text is not yet available. Dummy texts have been in use by typesetters since the 16th century.",
      btn: "Get Pricing",
      features: [
        {
          id: 1,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 2,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 3,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 4,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 5,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
      ],
    },
    {
      id:2,
      image: Image1,
      // title: "Who We Are ?",
      subtitle: "Our Company Story",
      subtext:
        "Start working with Upwind that can provide everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the publishing industry or by web designers to occupy the space which will later be filled with real content. This is required when, for example, the final text is not yet available. Dummy texts have been in use by typesetters since the 16th century.",
      btn: "Get Pricing",
      features: [
        {
          id: 1,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 2,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 3,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 4,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
        {
          id: 5,
          icon: "/checkmark.svg",
          title: "Pallet Size & Design: Customizable",
        },
      ],
    },
  ];

  return (
    <>
      <section className="relative  bg-gray-50 dark:bg-slate-800" id="about">
        {/* <div className="flex flex-col items-center px-4 md:px-0 mb-5">
        <div className="md:mx-[449px] py-7">
          <h1 className="md:text-[36px] text-[24px] text-[#4D4D4D] leading-tight text-center font-semibold">
            Solutions{" "}
          </h1>
        </div>
      </div> */}

        <div className="container mx-auto">
          <h3 className="mb-16 md:text-2xl text-xl  text-center font-medium dark:text-white">
            Products Section
          </h3>
          {team.map((item, key) => (
            <div
              key={key}
              className={`flex  ${
                item.id % 2 === 0 && "flex-row-reverse"
              }  lg:grid-cols-12 md:grid-cols-2 gap-10 items-center`}
            >
              <div className="lg:col-span-5">
                <div className="relative">
                  <img
                    src={item.image}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                  />
                  {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  <Link2 to="#" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-[#69A41D]">
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                  </Link2>
                </div> */}
                </div>
              </div>
              {/* end col */}

              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  {/* <h6 className="text-[#69A41D] text-base font-medium uppercase mb-2">
                    {item.title}
                  </h6> */}
                  <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                    {item.subtitle}
                  </h3>

                  <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto mb-4">
                    {item.subtext}
                  </p>

                  <ul className=" text-xl">
                    {item.features?.map((i) => (
                      <li className="flex flex-row items-center text-[16px]">
                        <img src={i.icon} className="size-4 mr-4" />
                        {i.title}
                      </li>
                    ))}
                  </ul>

                  <div className="relative mt-10">
                    <a
                      href="#contact"
                      className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
                    >
                      {item.btn}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <!-- End --> */}
      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      /> */}
    </>
  );
}
